import axios from "axios";

export default axios.create({
  //baseURL : 'backend-dwf.speckpro.com'
  //baseURL: 'http://127.0.0.1:8000/api'
  //baseURL : 'http://192.168.100.73:3300/api'
  // baseURL: 'http://192.168.100.57:8000/api/'
  // baseURL: 'https://dwf.walnuthash.com/api/'
  // baseURL: 'https://dwf.vyoclinic.com/api/'
  //baseURL: 'https://backend-dwf.speckpro.com/api/'
  baseURL: "https://dwftestbackend.speckpro.com/api/",
});
